<template>
  <v-container fluid>
    <v-card class="mt-5">
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Utente</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <UsersForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import UsersForm from '@components/admin/users/UsersForm.vue'

export default {
  name: 'UserCreate',
  components: { UsersForm },
  page: {
    title: 'Crea utente',
  },
  methods: {
    ...mapMutations('users', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted(id) {
      this.reset()
      this.$router.push({ name: 'admin.users', params: { id } })
    },
  },
}
</script>
