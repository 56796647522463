<template>
  <FormItem
    v-model="id"
    type="select"
    :values="list"
    name="Gruppo"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    :dense="dense"
  />
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'GroupsSelect',
  components: {
    FormItem,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    cols: {
      type: [String, Number],
      default: 12,
    },
    sm: {
      type: [String, Number],
      default: 6,
    },
    md: {
      type: [String, Number],
      default: 3,
    },
    lg: {
      type: [String, Number],
      default: 2,
    },
    xl: {
      type: [String, Number],
      default: 2,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('groups', ['list']),
    id: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      },
    },
  },
  mounted() {
    this.getDropdownList({})
  },
  methods: {
    ...mapActions('groups', ['getDropdownList']),
  },
}
</script>
