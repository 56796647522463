<template>
  <Form
    :hide-reset="edit"
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
  >
    <h4 class="mb-2">Dati</h4>
    <v-row dense>
      <FormItem
        v-if="false"
        v-model="type"
        rules="required"
        type="select"
        name="Tipo"
        :values="types"
        :lg="4"
      />
      <FormItem v-model="first_name" rules="required" name="Nome" :lg="4" />

      <FormItem v-model="last_name" rules="required" name="Cognome" :lg="3" />
      <FormItem
        v-model="username"
        name="Username"
        rules="required"
        :lg="3"
        :readonly="edit"
      />
      <FormItem
        v-if="canUser('admin.users', 'system')"
        v-model="type"
        type="select"
        :values="['internal', 'system']"
        name="Tipo Utenza"
        rules="required"
        :lg="4"
      />
    </v-row>
    <h4 class="mb-2">Riferimenti</h4>
    <v-row dense>
      <FormItem
        v-model="email"
        :rules="{ required: !mobile_phone_number, email: true }"
        name="Email"
        type="email"
        :lg="4"
      />
      <FormItem
        v-model="work_phone_number"
        rules="phone"
        name="Telefono"
        :lg="4"
      />
      <FormItem
        v-model="mobile_phone_number"
        :rules="{ required: !email, phone: true }"
        name="Cellulare"
        :lg="4"
      />
    </v-row>
    <v-row dense>
      <FormItem v-model="department" name="Dipartimento" :lg="4" />
      <GroupsSelect v-model="group_id" sm="12" md="6" lg="4" xl="4" />
      <RolesSelect
        v-model="role_id"
        rules="required"
        sm="12"
        md="6"
        lg="4"
        xl="4"
      />
    </v-row>

    <v-row dense>
      <FormCustomers
        v-if="!bindUserToCustomer"
        v-model="bound_customers"
        :customer_id="bound_customers"
        :disabled="bindUserToCustomer"
        clearable
        name="Abbina ad uno o più Clienti"
        hint="Ricerca per ragione sociale, codice o P.IVA. Non selezionare per visibilità completa."
        persistent-hint
        multi
        :sm="12"
        :md="8"
        :lg="8"
        :xl="8"
      />

      <FormCustomers
        v-if="bindUserToCustomer"
        v-model="customer_id"
        :customer_id="customer_id"
        :disabled="!bindUserToCustomer"
        clearable
        :rules="{ required: bindUserToCustomer }"
        name="Seleziona Cliente"
        hint="Inserisci ragione sociale, codice o P.IVA"
        persistent-hint
        :sm="12"
        :md="4"
        :lg="4"
        :xl="4"
      />
      <FormCustomersUsers
        v-if="bindUserToCustomer"
        ref="customersUsersSelect"
        v-model="customers_user_id"
        :customer_id="customer_id"
        :customer_user_id="customers_user_id"
        :disabled="!bindUserToCustomer || !customer_id"
        name="Utente"
        clearable
        :sm="12"
        :md="4"
        :lg="4"
        :xl="4"
      />

      <FormProjects
        v-model="projects"
        name="Limita a Progetto"
        hint="Non selezionare nessun progetto per visibilità totale."
        persistent-hint
        multi
        chips
        :md="4"
        :lg="4"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import GroupsSelect from '@components/admin/groups/GroupsSelect.vue'
import RolesSelect from '@components/admin/roles/RolesSelect.vue'
import { authComputed } from '@state/helpers.js'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormCustomersUsers from '@components/forms/FormCustomersUsers.vue'
import FormProjects from '@components/forms/FormProjects.vue'

const { mapFields } = createHelpers({
  getterType: 'users/getCurrent',
  mutationType: 'users/SET_CURRENT_FIELDS',
})

export default {
  name: 'UsersForm',
  components: {
    FormCustomersUsers,
    FormCustomers,
    RolesSelect,
    GroupsSelect,
    FormItem,
    Form,
    FormProjects,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'type',
      'username',
      'first_name',
      'last_name',
      'email',
      'department',
      'mobile_phone_number',
      'work_phone_number',
      'status',
      'role_id',
      'group_id',
      'customer_id',
      'customers_user_id',
      'projects',
      'bound_customers',
    ]),
    ...mapState('users', {
      id: (state) => state.current.id,
      types: (state) => state.types,
      loading: (state) => state.loading,
    }),
    ...mapState('roles', { roleList: 'list' }),
    currentRole() {
      return this.roleList.find((r) => r.value === this.role_id)
    },
    bindUserToCustomer() {
      return this.currentRole?.key.includes('customer')
    },
  },
  methods: {
    ...mapMutations('users', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('users', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (
        !this.bindUserToCustomer &&
        (this.customer_id || this.customers_user_id)
      ) {
        const res = await this.$dialog.confirm({
          text:
            'Il ruolo selezionato non prevede abbinamenti con il cliente e verranno pertanto eliminati. ' +
            "L'utenza avrà una visibilità dei dati superiore. Proseguire?",
          title: 'Attenzione',
        })

        if (!res) return

        console.info('Clearing customer binding because not needed by the role')
        this.customer_id = null
        this.customers_user_id = null
      }

      if (this.bindUserToCustomer && this.bound_customers?.length) {
        console.info(
          'Clearing bound customers because conflicting with selected role'
        )
        this.bound_customers = []
      }

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
